import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Box, Container, Flex, Icon } from '@chakra-ui/react';
import { FaEnvelope, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PlayIcon from '../images/play-icon-white.svg';
import '../styles/video.scss';

const Video = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const post = props.data.wpVideo;

  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: post.videoTags.videoUrl,
    light: post.featuredImage.node.sourceUrl,
    playing: true,
    controls: true,
    width: '100%',
    height: '700px',
  };

  return (
    <Layout>
      <SEO
        title="Enhanced Efficiency Fertilizers | Crystal Green | Ostara"
        description="Optimize plant nutrition by increasing uptake and reducing runoff with enhanced efficiency fertilizers designed for today's data-driven and environmentally aware growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhanced Efficiency Fertilizers"
      />
      <main className="video-page">
        <Box w="100%" className="video-body-container">
          <Flex direction="column" className="video-body-container--title">
            <Container
              marginTop={[10, 10, 10, 100]}
              marginBottom={[10, 10, 10, 100]}
              maxW={1400}
            >
              <Link to="/videos">Back</Link>

              <ReactPlayer className="module-video" {...config} />
              <h3>{post.title}</h3>

              <div className="video-body-container2--footer">
                <div>
                  <p className="videos-tags">Tag 1</p>
                  <p className="videos-tags">Tag 2</p>
                </div>
                <div className="share-container">
                  Share: <Icon as={FaFacebookF} />
                  <Icon as={FaLinkedinIn} />
                  <Icon as={FaEnvelope} />
                </div>
              </div>
            </Container>
          </Flex>
        </Box>
      </main>
    </Layout>
  );
};

export default Video;
export const VideoQuery = graphql`
  query ($id: String) {
    wpVideo(id: { eq: $id }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      videoTags {
        videoUrl
        videoTags
      }
      slug
    }
  }
`;
